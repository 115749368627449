<template>
  <div class="Activity">
    <v-row class="Activity__filter-row">
      <v-col cols="2">
        <v-text-field
            v-on:input="debounceInput"
            outlined
            dense
            v-model="search"
            name="Search"
            label="Search" />
      </v-col>
      <v-col cols="2">
        <app-date-picker
          :range="true"
          v-model="dateRange"
          name="date"
          label="Time Interval" />
      </v-col>
      <v-col
        cols="2"
        class="time">
        Server Time (UTC):
        {{ formatTime(new Date()) }}
      </v-col>
    </v-row>

    <div class="Activity__tabs">
      <v-tabs
        v-model="selectedTab"
        @change="onSelectedTabChange">
        <v-tab
          v-for="tab in tabs"
          :key="tab.name"
          :disabled="tab.disabled">
          {{ tab.name }}
        </v-tab>
      </v-tabs>
      <v-btn
        icon
        color="green"
        @click="getInfo">
        <v-icon>mdi-cached</v-icon>
      </v-btn>
    </div>

    <v-tabs-items
      v-model="selectedTab"
      class="mt-6">
      <v-tab-item>
        <activity-workflow
          :rows="activity"
          :loading="isDataLoading"
          :stats-loading="activityStatsLoading"
          :query-data="{ dateRange, localTZ, search }"
          @get-stat="onGetStat" />
      </v-tab-item>
      <v-tab-item>
        <running-workflows
          v-if="isShAdmin"
          :rows="runningWorkflows"
          :loading="isDataLoading"
          :stats-loading="activityStatsLoading"
          @get-stat="onGetStat" />
      </v-tab-item>
      <v-tab-item>
        <failed-workflows
          v-if="isShAdmin"
          :rows="failedWorkflows"
          :loading="isDataLoading"
          :stats-loading="activityStatsLoading"
          @get-stat="onGetStat" />
      </v-tab-item>
      <v-tab-item>
        <activity-transaction
          :rows="activity"
          :loading="isDataLoading" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import * as moment from 'moment-timezone';
import { createNamespacedHelpers } from 'vuex';
import ActivityWorkflow from '@/components/activity-workflow/activity-workflow.vue';
import RunningWorkflows from '@/components/activity-workflow/running-workflows.vue';
import FailedWorkflows from '@/components/activity-workflow/failed-workflows.vue';
import ActivityTransaction from '@/components/activity-transaction/activity-transaction.vue';

const { mapGetters: customerGetters } = createNamespacedHelpers('customer');
const { mapGetters: activityGetters, mapActions: activityActions, } =
  createNamespacedHelpers('activity');

import AppDatePicker from '@/components/app-date-picker/app-date-picker.vue';
import debounce from "lodash/debounce";

export default {
  name: 'Activity',
  components: {
    ActivityWorkflow,
    ActivityTransaction,
    AppDatePicker,
    RunningWorkflows,
    FailedWorkflows,
  },
  data() {
    return {
      dateRange: [new Date(), new Date()],
      search: '',
      selectedTab: 0,
      localTZ: 'UTC',
    };
  },
  computed: {
    ...customerGetters({
      customer: 'CUSTOMER',
      selectedCustomer: 'SELECTED_CUSTOMER',
    }),
    ...activityGetters({
      activity: 'ACTIVITY',
      runningWorkflows: 'RUNNING_WORKFLOWS',
      failedWorkflows: 'FAILED_WORKFLOWS',
      activityLoading: 'ACTIVITY_LOADING',
      activityStatsLoading: 'ACTIVITY_STAT_LOADING',
      activityPage: 'GET_ACTIVITY_PAGE',
      activityLimit: 'GET_ACTIVITY_LIMIT',
    }),
    isShAdmin() {
      return this.customer && this.customer.customer_id === 0;
    },
    tabs() {
      const results = [{ name: 'workflows', disabled: false }];
      const extraTabs = [
        { name: 'transactions', disabled: true },
        { name: 'insurances', disabled: true },
        { name: 'patients', disabled: true },
      ];
      const allowedCustomers = [1, 2, 4, 5];
      const currCustID = this.customer.customer_id;

      if (this.isShAdmin) {
        return results.concat([
          { name: 'running workflows', disabled: false },
          { name: 'failed workflows', disabled: false },
          ...extraTabs,
        ]);
      }

      if (allowedCustomers.includes(currCustID)) {
        return results.concat(extraTabs);
      }

      return results;
    },
    currentTabName() {
      return this.tabs[this.selectedTab].name;
    },
    isDataLoading() {
      return this.activityLoading;
    },
  },
  watch: {
    dateRange() {
      this.getInfo();
    },
    'selectedCustomer.customer_id'() {
      this.getInfo();
    },
  },
  methods: {
    ...activityActions(['getActivity', 'getStat']),
    formatDate(date) {
      if (!date) return '';
      else return moment(date).tz(this.localTZ).format('YYYY-MM-DD HH:mm');
    },
    formatTime(date) {
      if (!date) return '';
      else return moment(date).tz('UTC').format('HH:mm');
    },
    getInfo() {
      const from = moment(this.dateRange[0]).format('YYYY-MM-DD');
      const to = moment(this.dateRange[1]).format('YYYY-MM-DD');

      if (!from || !to) return;

      this.getActivity({
        from: from,
        to: to,
        tz: this.localTZ,
        report_type: this.currentTabName,
        page:  this.activityPage - 1,
        limit: this.activityLimit,
        search: this.search,
      });
    },
    onGetStat(id) {
      this.getStat({ storageId: id });
    },
    setLocalTimezone() {
      this.localTZ = moment.tz.guess();
    },
    onSelectedTabChange() {
      this.getInfo();
    },
    debounceInput: debounce(function (e) {
      this.search = e;
      this.getInfo()
    }, 1000)
  },
  mounted() {
    this.setLocalTimezone();
    this.getInfo();
  },
};
</script>

<style lang="scss" scoped>
@import './activity';
</style>
